import groupModule from '../groupModule';

function groupComments(groupResources) {
    "ngInject";
    return {
        restrict: "A",
        templateUrl: groupResources.templates.groupCommentsTemplate
    }
};

groupModule.directive("groupComments", groupComments);

export default groupComments;
