import { GroupForumsService } from 'Roblox';
import groupModule from '../groupModule';

function groupForums() {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      group: '<',
      permissions: '<'
    },
    link(scope, element) {
      const renderForums = () => {
        GroupForumsService?.renderGroupForums(element[0], {
          group: scope.group,
          permissions: scope.permissions
        });
      };

      element.ready(renderForums);

      scope.$watch(
        () => ({ group: scope.group, permissions: scope.permissions }),
        (newVal, oldVal) => {
          // Don't rerender until we have both group and permissions
          if (!newVal.group?.id || !newVal.permissions) {
            return;
          }

          // Only rerender if the group id or permissions have changed
          if (
            newVal.group.id !== oldVal.group.id ||
            JSON.stringify(newVal.permissions) !== JSON.stringify(oldVal.permissions)
          ) {
            renderForums();
          }
        },
        true
      );
    }
  };
}

groupModule.directive('groupForums', groupForums);

export default groupForums;
