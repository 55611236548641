import groupModule from "../groupModule";

function groupGamesItemController($filter, thumbnailConstants) {
    "ngInject";

    var ctrl = this;

    ctrl.goToGameDetails = function ($event) {
        var localTimestamp = new Date().toISOString();
        var gameReferralUrl = ctrl.game.gameReferralUrl;
        gameReferralUrl = $filter("formatString")(gameReferralUrl, { localTimestamp: localTimestamp });
        ctrl.game.gameReferralUrl = gameReferralUrl;
    };

    var init = function () {
        ctrl.thumbnailTypes = thumbnailConstants.thumbnailTypes;
    };

    ctrl.$onInit = init;
}

groupModule.controller("groupGamesItemController", groupGamesItemController);
export default groupGamesItemController;