import groupModule from '../groupModule';

const groupAnnouncements = {
  templateUrl: 'group-announcements',
  bindings: {
    group: '<',
    communityInfo: '<',
    isOwner: '<',
    announcement: '<',
    showLegacyShouts: '<',
    joinGroup: '<',
    allowedToJoinGroup: '<',
    policies: '<'
  },
  controller: 'groupAnnouncementsController'
};

groupModule.component('groupAnnouncements', groupAnnouncements);
export default groupAnnouncements;
