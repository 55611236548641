import groupModule from '../groupModule';

function groupWallService($filter, httpService, $q, groupsConstants, groupDetailsConstants) {
    "ngInject";

    return {
        sendPost: function (groupId, body, captchaData) {
            var config = {
                url: $filter("formatString")(groupDetailsConstants.urls.groupWallPosts, { id: groupId })
            };

            var data = {
                body: body
            };

            Object.assign(data, captchaData);

            return $q(function (resolve, reject) {
                httpService.httpPost(config, data).then(resolve, reject);
            });
        },

        deleteWallPost: function (groupId, postId) {
            var config = {
                url: $filter("formatString")(groupDetailsConstants.urls.deleteWallPost, { groupId: groupId, postId: postId })
            };

            return httpService.httpDelete(config);
        },

        subscribeToWallNotifications: function (groupId) {
            var config = {
                url: $filter("formatString")(groupDetailsConstants.urls.subscribeToWallNotificatons, { groupId: groupId })
            };

            return httpService.httpPost(config);
        },

        loadAppChatPrivacy: function () {
            var config = {
                url: groupDetailsConstants.urls.appChatPrivacy
            };

            return httpService.httpGet(config, {});
        }
    };
}

groupModule.factory("groupWallService", groupWallService);
export default groupWallService;
