import groupModule from '../groupModule';

const groupForums = {
  templateUrl: 'group-forums',
  bindings: {
    group: '<',
    permissions: '<'
  },
  controller: 'groupForumsController'
};

groupModule.component('groupForums', groupForums);
export default groupForums;
