import groupModule from '../groupModule';

function reportAbuseController($scope, $uibModalInstance, modalData, groupDetailsService) {
  'ngInject';

  $scope.abusePageUrl = function() {
    let url = '';
    switch ($scope.currentReport.name) {
      case $scope.params.reportAbuseTypes.group:
        url = groupDetailsService.abusePageUrl('group', $scope.params.groupId);
        break;
      case $scope.params.reportAbuseTypes.shout:
        url = groupDetailsService.abusePageUrl('groupstatus', $scope.params.groupId);
        break;
      case $scope.params.reportAbuseTypes.role:
        url = groupDetailsService.abusePageUrl('grouproleset', $scope.currentReport.role.id);
        break;
      case $scope.params.reportAbuseTypes.announcements:
        url = groupDetailsService.abusePageUrl('communitygroupstatus', $scope.params.groupId);
        break;
    }
    return url;
  };

  $scope.close = function() {
    $uibModalInstance.dismiss();
  };

  $scope.init = function() {
    $scope.params = modalData;
    $scope.roles = modalData.roles;
    $scope.currentReport = {
      name: modalData.reportAbuseTypes.group,
      role: modalData.roles[0]
    };
  };

  $scope.init();
}

groupModule.controller('reportAbuseController', reportAbuseController);
export default reportAbuseController;
