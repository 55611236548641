import groupModule from '../groupModule';

function groupAbout(groupResources) {
    "ngInject";
    return {
        restrict: "A",
        templateUrl: groupResources.templates.groupAboutTemplate
    }
};

groupModule.directive("groupAbout", groupAbout);

export default groupAbout;
