import groupModule from '../groupModule';

const groupWall = {
  templateUrl: 'group-wall',
  bindings: {
    groupId: '<',
    role: '<',
    permissions: '<',
    metadata: '<'
  },
  controller: 'groupWallController'
};

groupModule.component('groupWall', groupWall);

export default groupWall;
