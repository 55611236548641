import groupModule from '../groupModule';

function groupTab(groupResources) {
  'ngInject';

  return {
    restrict: 'A',
    replace: true,
    scope: {
      activeTab: '<',
      tab: '<',
      numTabs: '<'
    },
    templateUrl: groupResources.templates.groupTabTemplate
  };
}

groupModule.directive('groupTab', groupTab);

export default groupTab;
