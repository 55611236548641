import { EnvironmentUrls } from 'Roblox';
import { urlService } from 'core-utilities';
import urls from '../constants/urlConstants';

const { developApi, adConfigurationApi, apiGatewayUrl } = EnvironmentUrls;

export const getUsersUniversesListConfig = pageCursor => {
  const cursorParam = pageCursor ? `&cursor=${pageCursor}` : '';
  return {
    withCredentials: true,
    url: `${developApi}${urls.usersUniversesListUrl}?limit=100${cursorParam}`
  };
};

export const getGroupsUniversesListConfig = (groupId, pageCursor) => {
  const cursorParam = pageCursor ? `&cursor=${pageCursor}` : '';
  return {
    withCredentials: true,
    url: `${developApi}/v1/groups/${groupId}/universes?limit=100${cursorParam}`
  };
};

export const getImpressionCostEstimateConfig = () => ({
  withCredentials: true,
  url: `${apiGatewayUrl}${urls.impressionCostEstimateUrl}`
});

export const getCreateAdConfig = () => ({
  withCredentials: true,
  url: `${adConfigurationApi}${urls.createAdUrl}`
});

export const getSponsoredAdListConfig = (universeId, groupId) => {
  let url;
  if (groupId) {
    url = urls.groupSponsorshipsListUrl.replace('{groupId}', groupId);
  } else {
    url = urls.userSponsorshipsListUrl;
  }

  const params = {
    universeId
  };

  return urlService.getUrlWithQueries(url, params);
};

export const getAdsMetadataConfig = () => ({
  withCredentials: true,
  url: `${apiGatewayUrl}${urls.adsMetadataUrl}`
});

export const getAgeRecommendationConfig = () => ({
  withCredentials: true,
  retryable: true,
  url: `${apiGatewayUrl}/experience-guidelines-api/experience-guidelines/get-age-recommendation`
});
