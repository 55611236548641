import groupModule from "../groupModule";

const groupMembersList = {
    templateUrl: "group-members-list",
    bindings: {
        "isAuthenticatedUser": "<",
        "groupId": "<",
        "roles": "<"
    },
    controller: "groupMembersListController"
};

groupModule.component("groupMembersList", groupMembersList);

export default groupMembersList;