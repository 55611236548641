import groupModule from '../groupModule';

function groupStoreController(
  $scope,
  $filter,
  groupStoreService,
  groupDetailsConstants,
  thumbnailConstants,
  cursorPaginationService,
  creatorTypeConstants
) {
  'ngInject';

  const ctrl = this;

  ctrl.getDevelopPageUrl = () => {
    return `/develop/groups/${ctrl.groupId}?view=11`;
  };

  ctrl.getCatalogPageUrl = () => {
    return `/catalog?Category=1&CreatorName=${encodeURIComponent(ctrl.groupName)}&CreatorType=${creatorTypeConstants.creatorTypes.group}`;
  };

  $scope.storePager = cursorPaginationService.createPager({
    sortOrder: cursorPaginationService.sortOrder.Desc,
    pageSize: groupDetailsConstants.storePageSize,
    loadPageSize: groupDetailsConstants.cursorPageLoadSize,

    getCacheKeyParameters(params) {
      return {
        category: params.category,
        sortType: params.sortType,
        creatorType: params.creatorType,
        creatorTargetId: params.creatorTargetId
      };
    },

    getRequestUrl() {
      return $filter('formatString')(groupDetailsConstants.urls.catalogSearch);
    },

    beforeLoad() {
      ctrl.layout.isLoading = true;
      ctrl.layout.loadError = false;
    },

    loadSuccess(items) {
      ctrl.layout.isLoading = true;
      groupStoreService.getItemDetails(items).then(details => {
        if (ctrl.layout.isInfiniteScroll) {
          // Append if infinite scroll
          ctrl.storeItems = ctrl.storeItems.concat(details.data);
        } else {
          ctrl.storeItems = details.data;
        }
        ctrl.layout.isLoading = false;
      });
    },

    loadError(errors) {
      ctrl.storeItems = [];
      ctrl.layout.isLoading = false;
      ctrl.layout.loadError = true;
    }
  });

  ctrl.isInfiniteScrollingDisabled = () => {
    return !ctrl.layout.isInfiniteScroll || ctrl.layout.isLoading;
  };

  const init = () => {
    ctrl.isInitialized = true;

    ctrl.layout = {
      isInfiniteScroll: ctrl.metadata.isPhone
    };

    ctrl.storeItems = [];

    ctrl.thumbnailTypes = thumbnailConstants.thumbnailTypes;

    $scope.storePager.setPagingParameter('category', 'All');
    $scope.storePager.setPagingParameter('sortType', 'Updated');
    $scope.storePager.setPagingParameter('creatorType', 'Group');
    $scope.storePager.setPagingParameter('creatorTargetId', ctrl.groupId);
    $scope.storePager.loadFirstPage();
  };

  const onChanges = changesObj => {
    // If we haven't initialized this yet, return
    if (!ctrl.isInitialized) {
      return;
    }

    if ('groupId' in changesObj) {
      $scope.storePager.setPagingParameter('creatorTargetId', ctrl.groupId);
      $scope.storePager.loadFirstPage();
    }
  };

  ctrl.$onInit = init;
  ctrl.$onChanges = onChanges;
}

groupModule.controller('groupStoreController', groupStoreController);
export default groupStoreController;
