import groupModule from '../groupModule';

function groupCatalogItem(groupResources) {
    "ngInject";
    return {
        restrict: "A",
        templateUrl: groupResources.templates.groupCatalogItemTemplate
    }
};

groupModule.directive("groupCatalogItem", groupCatalogItem);

export default groupCatalogItem;