import groupModule from '../groupModule';

function groupStoreItemController(thumbnailConstants) {
  'ngInject';

  const ctrl = this;

  const init = () => {
    ctrl.thumbnailTypes = thumbnailConstants.thumbnailTypes;
  };

  ctrl.$onInit = init;
}

groupModule.controller('groupStoreItemController', groupStoreItemController);
export default groupStoreItemController;
